import React from 'react';
import { Link } from 'react-router-dom';

import './Card.css';

export default class Card extends React.Component{

  render() {
  	const work = this.props.item

    return (
    <Link to={"/" + this.props.item.id} >
      <div className = "card">
        <div>{work.fields.Images.map((image,i) => <img src={image.thumbnails.large.url}/>)}</div>
        {/*{work.fields.Title + ', ' + work.fields.Date + ', ' + work.fields.Medium}*/}
      </div>
     </Link>
    )
  }
}