import React, {useState, useEffect} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Home';
import Archive from './Archive';
import News from './News';
import Contact from './Contact';
import Item from './components/Item';
import Header from './components/Header';

import dotenv from 'dotenv'; 

import './App.css';

dotenv.config()

const process_api_key = process.env.REACT_APP_AIRTABLE_API_KEY;
const airtable_api_url = process.env.REACT_APP_AIRTABLE_API_URL;
const airtable_table = process.env.REACT_APP_AIRTABLE_TABLE;
const airtable_base = process.env.REACT_APP_AIRTABLE_BASE; 


Date.prototype.yyyymmdd = function() {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(),
					(mm>9 ? '' : '0') + mm,
					(dd>9 ? '' : '0') + dd
				 ].join('');
};


//fisher yates algorithm, lol
function random(seed) {
	var x = Math.sin(seed++) * 10000; 
	return x - Math.floor(x);
}

//shuffles paintings according to date
function shuffle(array) {
	let date = new Date();
	let seed = date.yyyymmdd();
	let m = array.length, t, i;

	// While there remain elements to shuffle...
	while (m) {

		// Pick a remaining element...
		i = Math.floor(random(seed) * m--);

		// And swap it with the current element.
		t = array[m];
		array[m] = array[i];
		array[i] = t;
		++seed
	}

	return array;
}


function App() {
	
	const [airtableData, setAirtableData] = useState([]);
	const [news, setNews] = useState([]);
	const [contact, setContact] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [headers, setHeaders] = useState([])

	useEffect(()=>{
		fetch(`${airtable_api_url}/${airtable_base}/News`, {
			 "headers": {"Authorization": `Bearer ${process_api_key}`}
		})
		.then(res => res.json())
		.then((data) => {
			//sort by date
			const sorted = data.records.sort((a, b) => b.fields.Date > a.fields.Date)
			setNews(sorted)
		})
	},[])

	useEffect(()=>{
		fetch(`${airtable_api_url}/${airtable_base}/Contact`, {
			 "headers": {"Authorization": `Bearer ${process_api_key}`}
		})
		.then(res => res.json())
		.then((data) => {
			setContact(data.records)
		})
	},[])


	useEffect(()=>{
		fetch(`${airtable_api_url}/${airtable_base}/${airtable_table}`, {
			 "headers": {"Authorization": `Bearer ${process_api_key}`}
		})
		.then(res => res.json())
		.then((data) => {
			const shuffledArr = shuffle(data.records)
			setAirtableData(shuffledArr);
		})
		setIsLoading(false);
	},[])

	return (

		<div className="App">
			<BrowserRouter>
				<Helmet>
					<title>george kan dot art</title>
					<meta name="description" content="george kan's portfolio" />
					<meta name="keywords" content="art, george kan, painting, artist, painter, crabs, crabs, crabs" />
				</Helmet>
				<div className="content">
				<Switch>
					<Route exact path="/">
					 <Home airtableData={airtableData} isLoading={isLoading}/>
					</Route>
					<Route exact path="/archive">
					 <Archive airtableData={airtableData} isLoading={isLoading}/>
					</Route>
					<Route exact path="/contact">
					 <Contact contact={contact} isLoading={isLoading}/>
					</Route>
					<Route exact path="/recent">
					 <News news={news} isLoading={isLoading}/>
					</Route>
					<Route path="/:id">
						<Item airtableData={airtableData}  isLoading={isLoading}/>
					</Route>
				</Switch>
				</div>
			</BrowserRouter>

		</div>
	);
}

export default App;