import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import {changeColour, removeColour, mod} from './helpers'
import { Redirect } from 'react-router';

import './Home.css'

export default class Contact extends React.Component{

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
			<Link to="/archive">
				<h1 className="title" onMouseEnter={changeColour} onMouseLeave={removeColour}>
					george kan
				</h1>
			</Link>
				{ this.props.contact.length > 0 && <div className = "textblock">
					{this.props.contact[0].fields.Notes}
				</div> }
			</div>
		)
	}
}