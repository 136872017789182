import $ from 'jquery';

export function removeColour(event){
	let el = event.target;
	$(el).css({'color': 'black'})
}

export function changeColour(event){
	let el = event.target;
	const colour = "hsl(" + 360 * Math.random() + ',' +
		(25 + 50 * Math.random()) + '%,' + 
		(75 + 10 * Math.random()) + '%)'
	$(el).css({'color': colour})
}

export function mod(n, m) {
	return ((n % m) + m) % m;
}
