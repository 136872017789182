import React from 'react';
import { Link } from 'react-router-dom';


export default class Header extends React.Component{

  render() {
    return (
      <div className = "header">
        <h3><span><Link to="/">home</Link></span> <span><Link to="/archive">index</Link></span></h3>
      </div>
    )
  }
}