import React from 'react';
import Card from './components/Card';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import $ from 'jquery';

import './App.css';
import './Archive.css';

export default class Archive extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			currentArray: [],
			width: window.innerWidth
		}
		this.handleResize = this.handleResize.bind(this);
	}

	removeColour(event) {
		let el = event.target;
		$(el).css({'color': 'black'})
	}

	changeColour(event) {
		let el = event.target;
		const colour = "hsl(" + 360 * Math.random() + ',' +
			(25 + 50 * Math.random()) + '%,' + 
			(75 + 10 * Math.random()) + '%)'
		$(el).css({'color': colour})
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize = (width, event) => {
		this.setState({ width: window.innerWidth });
	};


	static getDerivedStateFromProps(props, state) {
		return { 
			currentArray: props.airtableData.filter(image => image.fields.Display === true) };
	}

	render() {

		const { width } = this.state;
		const isMobile = width <= 600;

		return (
			<div>
				{ isMobile && 
					<div className="titlebar">
						<Link to="/"><h2 onMouseEnter={this.changeColour} onMouseLeave={this.removeColour}>george kan</h2></Link> 
						<Link to='/recent'><h2 onMouseEnter={this.changeColour} onMouseLeave={this.removeColour}>recent</h2></Link> 
						<Link to='/contact'><h2 onMouseEnter={this.changeColour} onMouseLeave={this.removeColour}>contact</h2></Link>
					</div>
				}

				{ !isMobile && 
					<div className="titlebar">
						<Link to="/"><h1 onMouseEnter={this.changeColour} onMouseLeave={this.removeColour}>george kan</h1></Link> 
						<Link to='/recent'><h1 onMouseEnter={this.changeColour} onMouseLeave={this.removeColour}>recent</h1></Link> 
						<Link to='/contact'><h1 onMouseEnter={this.changeColour} onMouseLeave={this.removeColour}>contact</h1></Link>
					</div>
				}
			{/*menu goes here, includes link to exhibitions and about and a contact form*/}
				<div id="archive">
					{ this.state.currentArray.length > 0 && this.state.currentArray.map((item,i) => 
						<Card key={i} item={item} />)}
				</div>
			</div>
		)
	}
}