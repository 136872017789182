import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import {changeColour, removeColour, mod} from './helpers'
import { Redirect } from 'react-router';

import './Home.css'

export default class News extends React.Component{

	constructor(props) {
		super(props);
	}

	render() {
		const newsArr = this.props.news.length > 0 ? this.props.news.filter(item => item.fields.Published === true) : null

		return (
			<div>
			<div>
			<Link to="/archive">
				<h1 className="title" onMouseEnter={changeColour} onMouseLeave={removeColour}>
					george kan
				</h1>
			</Link>
			</div>
				<div className="textblock">
					{ newsArr && 
						newsArr.map((item,i) => 
							<div key={i} className="newsItem">
							{item.fields.URL ? 
								<u><a href={item.fields.URL} onMouseEnter={changeColour} onMouseLeave={removeColour}><h3>{item.fields.Name}</h3></a></u>
							: <h3>{item.fields.Name}</h3> }
							{item.fields.Date && <h4>{item.fields.Date}</h4> }
							{item.fields.Images && <img className="newsImg" src={item.fields.Images[0].thumbnails.full.url} /> }
							<p>{item.fields.Notes}</p>
							<p>_______________________________</p>
							</div>
						)
					}
				</div>
			</div>
		)
	}
}