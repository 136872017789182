import React from 'react';
import { withRouter, Link } from "react-router-dom";
import './Item.css'
import { changeColour, removeColour, mod } from '../helpers'

class Item extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      loading: true,
      redirect: false,
      redirectLocation: '',
      id: '',
      work: '',
      ids: [],
      index: 0
    }
  }

  render() {

    const { id } = this.props.match ? this.props.match.params : 1;
    const work = this.props.airtableData.length > 0 ? this.props.airtableData.find(el => el.id === id) : null
    const ids = this.props.airtableData.filter(image => image.fields.Display === true).map( (item) => item.id)
    const index = ids.indexOf(id);

    return (
      <div>
        <Link to="/archive"><h1 className="title" onMouseEnter={changeColour} onMouseLeave={removeColour}>george kan</h1></Link>
        {work &&
          <div>
          <Link to={"/" + ids[mod(index-1, ids.length)]}><div id="leftclick" className="clicker"> </div></Link>
          <div className="works" >
            { <img src={work.fields.Images[0].thumbnails.full.url}/> }
          </div>
            <div className="caption"><i>{work.fields.Title}</i><span>{', ' + work.fields.Date + ', ' + work.fields.Medium}</span></div>
        <Link to={"/" + ids[mod(index+1, ids.length)]}><div id="rightclick" className="clicker"></div></Link>
        </div>}
     </div>
    )
  }
}

export default withRouter(Item);