import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import {changeColour, removeColour, mod} from './helpers'
import { Redirect } from 'react-router';

import './Home.css'

export default class Home extends React.Component{

	constructor(props) {
		super(props);
		this.changeCounter = this.changeCounter.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.state = {
			counter: 0,
			menu: false,
			currentArray: [],
			seconds: 0,
			redirect: false,
			redirectLocation: ''
		}
	}

	componentDidMount() {
		this.timer = setInterval(() => {
			this.setState({ seconds: this.state.seconds + 1 });
		}, 1000);  }

	componentWillUnmount() {
		clearInterval(this.timer);
	}


	static getDerivedStateFromProps(props, state) {
		const frontPageArray = props.airtableData.filter(image => image.fields.FrontPage === true && 'Images' in image.fields)
		if(frontPageArray.length != state.currentArray.length) {
			return { currentArray: frontPageArray}
		}
		return
	}

	getAllTags(airtableData) {
		const tagList = []
		airtableData.forEach(work => {
			work.fields.Tags && work.fields.Tags.forEach(tag => {
				if(!tagList.includes(tag)) tagList.push(tag)
			})
		})
		return tagList
	}


	toggleMenu() {
		this.setState({ menu: !this.state.menu })
	}

	resetCounter() {
		this.setState({ counter: 0 })
	}

	changeCounter = value => () => {
		this.setState({ seconds: 0 })
		const nextPosition = mod((this.state.counter+value), this.state.currentArray.length)
		if (nextPosition === 0) {
			const newArr = this.state.currentArray.sort((a, b) => 0.5 - Math.random())
			this.setState({currentArray: newArr})
		}
		this.setState({ counter: nextPosition })
	}

	handleRedirect = location => () => {
		this.setState({redirectLocation: location});
		this.setState({redirect: true});
	}


	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirectLocation} />;
		}

		const tags = this.props.airtableData.length > 0 ? 
			this.getAllTags(this.props.airtableData) : null;

		const currentWork = this.state.currentArray.length > 0 ? 
			this.state.currentArray[this.state.counter] : null;

		if(this.state.seconds%10 === 0 && this.state.seconds !==0){
			this.changeCounter(1)
		}

		return (
			<div>
			<Link to="/archive">
				<h1 className="title" onMouseEnter={changeColour} onMouseLeave={removeColour}>
					george kan
				</h1>
			</Link>
			<div id="leftclick" className="clicker" onClick={this.changeCounter(-1)}> </div>
				{ currentWork && <div className = "works clickable">
					<img onClick={this.handleRedirect("/"+currentWork.id)} src={currentWork.fields.Images[0].thumbnails.full.url} />
				</div> }
			<div id="rightclick" className="clicker" onClick={this.changeCounter(1)}> </div>
			</div>
		)
	}
}